import React from "react";
// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import TalkSchedule from "./talkschedule";
import "../hasuracon/styles.scss";
// const hasConLogo = require('../hasuracon/images/has-con-logo.svg');
// const pattern = require('../hasuracon/images/pattern.svg');
// const back = require('../hasuracon/images/back.svg');
const TalksBanner = () => {
  return (
    <section
      id="agenda"
      className="commonSectionWrapper topBannerWrapperHasuraConRel removePaddTop"
    >
      <div className="containerWrapper">
        {/*
        <div className='topBannerWrapperHasuraCon'>
          <div className='backHasuraConf'>
            <Link to='/events/hasura-con-2020/'>
              <img src={back} alt='back'/>
              <span>Conference page</span>
            </Link>
          </div>
          <div className='frameCon removePaddBottom'>
            <img src={hasConLogo} alt='Hasuracon20'/>
          </div>
        </div>
        */}
        <TalkSchedule />
      </div>
      {/*
      <div className='patternImg talksImg'>
        <img src={pattern} alt='Pattern'/>
      </div>
      */}
    </section>
  );
};

export default TalksBanner;
