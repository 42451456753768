import React from "react";

import Seo from "../../components/seo";
import HasuraConHeader from "../../components/hasuracon/hasuraconheader";
import "../../components/hasuracon/styles.scss";
import TopBanner from "../../components/hasuracon/topbanner";
import HasuraConWorkshop from "../../components/hasuracon/hasuraconworkshop";
// import HasuraConTalks from '../../components/hasuracon/hasuracontalks'
import TalksBanner from "../../components/talksdetails/talksbanner";
import InterestedSpeaker from "../../components/hasuracon/interestedspeaker";
// import HasuraConSponsor from '../../components/hasuracon/hasuraconsponsor'
import Footer from "../../components/common/footer";
import HasuraConFooter from "../../components/hasuracon/hasuraconfooter";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-card.png",
};

const canonicalUrl = "https://hasura.io/events/hasura-con-2020/";

const HasuraCon = props => (
  <div id="viewport" className="wd100 viewport">
    <div className="blueGradientBgColor">
      <Seo
        title="HasuraCon’20: An online Hasura GraphQL user conference"
        description="HasuraCon is a free, online conference. Join us for a week of talks and hand-on workshops about pushing the boundaries of GraphQL forward!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <HasuraConHeader location={props.location} />
      <TopBanner />
      <HasuraConWorkshop />
      {/* <HasuraConTalks/> */}
      <TalksBanner />
      <InterestedSpeaker />
      {/* <HasuraConSponsor/> */}
      <HasuraConFooter location={props.location} />
    </div>
    <Footer location={props.location} />
  </div>
);

export default HasuraCon;
