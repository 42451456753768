import React from "react";
// import PropTypes from "prop-types"
// import { trackGAEvents } from "../common/trackGA";
import "./styles.scss";
import hasConLogo from "./images/has-con-logo.svg";
import june from "./images/june.svg";
import pattern from "./images/pattern.svg";
const TopBanner = () => {
  return (
    <section className="commonSectionWrapper topBannerWrapperHasuraConRel addTopbannerPadd">
      <div className="containerWrapper">
        <div className="topBannerWrapperHasuraCon">
          <div className="col-md-8 col-sm-8 col-xs-12 noPadd">
            <div className="frameCon">
              <img src={hasConLogo} alt="Hasuracon20" />
            </div>
            <div className="date">
              <img src={june} alt="Date" />
            </div>
            <h1 className="title">The Hasura User Conference</h1>
            <div className="bodyTextCon">
              HasuraCon is a free, online conference. Join us for a week of talks and hand-on
              workshops about pushing the boundaries of GraphQL forward!
            </div>
            <div className="buttonWrapper">
              <a
                href="https://www.youtube.com/playlist?list=PLTRTpHrUcSB9BmfyXmhplz874ySHeKlYx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="commonBtnCon hasuraConRegBtn">Watch the Talks</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="patternImg">
        <img src={pattern} alt="Pattern" />
      </div>
    </section>
  );
};

export default TopBanner;
