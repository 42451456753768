import React from "react";
import { Link } from "gatsby";
// import PropTypes from "prop-types"
import "./styles.scss";
import workshopIcon from "./images/workshop-icon.svg";
const worskshopState = [
  {
    date: "18th June | 9am to 12pm PST",
    title: "Enterprise-grade GraphQL Authorization",
    description:
      "Learn how to connect your auth systems to Hasura and how to implement any kind of authorization: rules, roles, tags, hierarchies, attribute based systems!",
    btnText: "Event Over",
  },
  {
    date: "19th June | 9am to 12pm PST",
    title: "Architecting domain-driven GraphQL apps",
    description:
      "Setup a reference architecture for your team to build complex GraphQL apps with Hasura. Integrate your favourite frontend, backend, CI/CD tooling for maximum feature velocity.",
    btnText: "Event Over",
  },
  {
    date: "15th July | 9am to 12pm PDT",
    title: "Scaling Hasura from 10 to 1M reqs/s",
    description:
      "Scale a fully loaded GraphQL application built with Hasura (complete with auth & custom business logic) from 10 req/s to 1 million req/s without any downtime.",
    btnText: "Event Over",
  },
];
const HasuraConWorkshop = () => {
  const workshopList = worskshopState.map((list, index) => {
    return (
      <div key={index} className="hasuraConWorkshopList">
        <div className="extraSmallBodyTextCon">{list.date}</div>
        <h3 className="smallParaHeaderCon">{list.title}</h3>
        <div className="bodyTextSmallCon">{list.description}</div>
        {list.internalLink ? (
          <Link to={list.internalLink}>
            <div className="buttonWrapper">
              <button className="commonBtnCon hasuraConNavBtn">{list.btnText}</button>
            </div>
          </Link>
        ) : (
          <div className="buttonWrapper">
            <button className="commonBtnCon hasuraConNavBtn" disabled>
              {list.btnText}
            </button>
          </div>
        )}
      </div>
    );
  });
  return (
    <section id="workshop">
      <div className="containerWrapper">
        <div className="hasuraConWorkshopWrapper">
          <div className="col-md-6 col-sm-6 col-xs-12 noPadd">
            <div className="hasuraConWorkshopLive">
              <div className="extraSmallBodyTextCon"> 18 - 25 June</div>
              <div className="bodyTextCon">GraphQL Workshops</div>
              <h2 className="sectionHeaderCon">Live hands-on workshops by the core Hasura team.</h2>
              <div className="hasuraConWorkshopImg">
                <img src={workshopIcon} alt="Workshop" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 noPadd">
            <div className="hasuraConWorshopListWrapper">{workshopList}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HasuraConWorkshop;
